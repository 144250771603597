<template>
  <div v-show="!$apollo.loading" style="width: 100%;">
    <file-pond
      v-if="groupSlug"
      ref="pond"
      class-name="title-image-file"
      :label-idle="label"
      :allow-multiple="false"
      :allowPaste="false"
      :allowReplace="true"
      :dropOnElement="false"
      :forceRevert="true"
      maxFiles="1"
      :files="files"
      :allowFileTypeValidation="true"
      :accepted-file-types="allowedFileTypes"
      :allowImageEditor="allowImageEditMode"
      :imageEditor="editorSettings"
      :allowImageFocalpointEdit="allowImageFocalpointEdit"
      :image-focalpoint-editor="imageFocalpointEditor"
      :imageFocalpointSet="imageFocalpointSet"
      :allowImageSizeMetadata="true"
      :imageEditorAllowEdit="true"
      :allowFileSizeValidation="allowFileSizeValidation"
      :allowImageValidateSize="true"
      :allowImagePreview="true"
      :imageValidateSizeMinWidth="imageValidateSizeMinWidth"
      :imageValidateSizeMaxWidth="imageValidateSizeMaxWidth"
      :imageValidateSizeMinHeight="imageValidateSizeMinHeight"
      :imageValidateSizeMaxHeight="imageValidateSizeMaxHeight"
      :imageEditorAfterWriteImage="imageEditorAfterWriteImage"
      :maxFileSize="maxFileSize"
      :onerror="handleErrorMethod"
      :server="{ process, revert, load }"
      v-on:init="handleFilePondInit"
      credits="false"
      @removefile="$emit('file-removed')"
      @processfile="resetFileInput"
      :fileValidateTypeDetectType="getCustomMIMEType"
      :allowRemove="fileCanBeRemoved"
    />
  </div>
</template>

<script>
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import GetGroupObjectByGroupId from '@/graphQlQueries/queries/getGroupObjectByGroupId';
import { uploadFileToAws } from '@/plugins/aws';
import InsertImage from '@/graphQlQueries/mutations/insertImages';
import slugify from '@sindresorhus/slugify';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEditor from 'filepond-plugin-image-editor';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginImageSizeMetadata from 'filepond-plugin-image-size-metadata';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';

import FilePondPluginFocalpointEdit from '@/plugins/filepond-plugin-image-focalpoint';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';

// Import pintura
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,

  // filepond
  openEditor,
  processImage,
  createDefaultImageOrienter,
  legacyDataToImageState,
  createShapePreprocessor,
  createDefaultFrameStyles,
  createDefaultLineEndStyles,
  createLineEndProcessor,
  createFrameStyleProcessor,
} from 'pintura';

import Draggable from '@/plugins/draggable';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFocalpointEdit,
  FilePondPluginImageEditor,
  FilePondPluginImageSizeMetadata,
  FilePondPluginImageValidateSize,
  FilePondPluginMediaPreview,
);

global.Draggable = Draggable;

export default {
  name: 'ImageUpload',
  components: {
    FilePond,
  },
  props: {
    imageObject: {
      type: Object,
      required: false,
      default() {
        return {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        };
      },
    },
    acceptedFileTypes: {
      type: String,
      required: false,
    },
    filePosterHeight: {
      type: String,
      required: false,
    },
    allowImageEdit: {
      type: Boolean,
      required: false,
    },
    allowImageFocalpointEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowFileSizeValidation: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    fileCanBeRemoved: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      label: this.$t('webmag.uploadLabel'),
      imageValidateSizeMinWidth: 1,
      imageValidateSizeMinHeight: 1,
      imageValidateSizeMaxWidth: 65535,
      imageValidateSizeMaxHeight: 65535,
      acceptedFileTypesDefault: ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml'],
      customFontFileType: null,
      files: [],
      editorSettings: {
        // map legacy data objects to new imageState objects
        legacyDataToImageState,

        createEditor: openEditor,

        // Required, used for reading the image data
        imageReader: [createDefaultImageReader],

        // optionally. can leave out when not generating a preview thumbnail and/or output image
        imageWriter: [
          createDefaultImageWriter,
          {
            quality: 1, // 1 equals to 100%
          },
        ],

        // used to generate poster images, runs an editor in the background
        imageProcessor: processImage,

        // editor options
        editorOptions: {
          allowImageEditor: true,
          imageEditorWriteImage: false,
          imageEditorSupportImage: (file) => {
            console.log(file);
          },
          cropSelectPresetOptions: [
            [undefined, 'Custom'],
            [1, 'Square'],

            // shown when cropSelectPresetFilter is set to 'landscape'
            [2 / 1, '2:1'],
            [3 / 2, '3:2'],
            [4 / 3, '4:3'],
            [16 / 10, '16:10'],
            [16 / 9, '16:9'],

            // shown when cropSelectPresetFilter is set to 'portrait'
            [1 / 2, '1:2'],
            [2 / 3, '2:3'],
            [3 / 4, '3:4'],
            [10 / 16, '10:16'],
            [9 / 16, '9:16'],
          ],
          imageOrienter: createDefaultImageOrienter(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...markup_editor_locale_en_gb,
          },
          shapePreprocessor: createShapePreprocessor([
            // set default line end styles
            createLineEndProcessor(createDefaultLineEndStyles()),

            // set default frame styles
            createFrameStyleProcessor(createDefaultFrameStyles()),
          ]),
        },
      },
      allowImageEditMode: true,
      imageFocalpointEditor: {
        onDragEnd: (output) => {
          console.log('onDragEnd', output);
          this.storageObjectOfResizedFile.focalpoint = {
            x: output.x,
            y: output.y,
          };
          // emit the whole image object to the parent component
          this.$emit('upload-image-object', this.storageObjectOfResizedFile);
        },
      },
      imageFocalpointSet: null,
      allowImageFocalpointEditMode: true,
      storageObjectOfResizedFile: {
        url: null,
        width: null,
        height: null,
        focalpoint: {
          x: 50,
          y: 50,
        },
      },
      internalFilePath: null,
      resizedFilePath: null,
      groupSlug: null,
    };
  },
  mixins: [UserDataAndAccess],
  apollo: {
    groupSlug: {
      query: GetGroupObjectByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        return (data.groups[0]?.slug)
          ? data.groups[0].slug
          : null;
      },
      skip() {
        return !this.groupId;
      },
    },
  },
  created() {
    if (this.imageObject) {
      if (this.imageObject.url) {
        this.files = [{
          // source: 'https://webmag.io/public/exampleMedia/snow.mp4',
          source: this.imageObject.url,
          options: {
            type: 'local',
          },
        }];
      }
      this.storageObjectOfResizedFile = {
        ...this.storageObjectOfResizedFile,
        ...this.imageObject,
      };
      if (this.imageObject.focalpoint) {
        this.imageFocalpointSet = this.imageObject.focalpoint;
      }
    }
    if (this.allowImageEdit === false) {
      this.allowImageEditMode = true;
    }
    if (this.allowImageFocalpointEdit === false) {
      this.allowImageFocalpointEditMode = true;
    }
  },
  computed: {
    maxFileSize() {
      if (this.userObject && this.userObject.isSysAdmin) {
        return null;
      }
      return '30MB';
    },
    allowedFileTypes() {
      return (this.acceptedFileTypes)
        ? this.acceptedFileTypes.split(',')
        : this.acceptedFileTypesDefault;
    },
  },
  methods: {
    // For fonts, when browser looks for all files instead of specific ones
    getCustomMIMEType(source, type) {
      let fileType = type;
      if (source.name.slice(-4) === '.otf') {
        fileType = 'font/otf';
        this.customFontFileType = 'font/otf';
      } else if (source.name.slice(-4) === '.ttf') {
        fileType = 'font/ttf';
        this.customFontFileType = 'font/ttf';
      } else if (source.name.slice(-5) === '.woff') {
        fileType = 'font/woff';
        this.customFontFileType = 'font/woff';
      } else if (source.name.slice(-6) === '.woff2') {
        fileType = 'font/woff2';
        this.customFontFileType = 'font/woff2';
      }
      return Promise.resolve(fileType);
    },
    // setImageEditorSupportImage: (file) => {
    //   console.log(file);
    // },
    imageEditorAfterWriteImage: ({ src, imageState }) => new Promise(
      (resolve, reject) => {
        // use Pintura Image Editor to process the source image again
        if (src.type === 'image/svg+xml') {
          resolve([
            { name: 'svg', file: src },
          ]);
          return;
        }
        if (src.type === 'image/gif') {
          resolve([
            { name: 'gif', file: src },
          ]);
          return;
        }
        processImage(src, {
          imageReader: createDefaultImageReader(),
          imageWriter: createDefaultImageWriter({
            targetSize: {
              width: 1920,
              height: 1920,
              fit: 'contain',
              upscale: false,
            },
          }),
          imageState,
        })
          // we get the thumbnail and add it to the files
          .then((resizedImage) => {
            resolve([
              { name: '1920x1920', file: resizedImage.dest },
            ]);
          }).catch(reject);
      },
    ),
    handleFilePondInit() {
      // this.$refs.pond.getFiles();
      console.log('FilePond has initialized');
    },
    // eslint-disable-next-line no-unused-vars
    process(fieldName, files, metadata, load, error, progress, abort) {
      console.log('fieldName', fieldName);
      console.log('files', files);
      const timestamp = new Date().getTime();
      if (
        files.type
        && ('audio/mp3,audio/mpeg'.includes(files.type) || 'video/mp4'.includes(files.type) || 'video/webm'.includes(files.type))
      ) {
        // now we can upload an audio file!!!
        let filetype = '';
        if (files.type === 'video/mp4') {
          filetype = 'video/mp4';
        } else if (files.type === 'video/webm') {
          filetype = 'video/webm';
        } else {
          filetype = 'audio/mp3,audio/mpeg';
        }
        this.$emit('disable-modal-ok-button', true);
        this.uploadItem(files, timestamp, files.name, this.type).then(async (fileUrl) => {
          console.log('after upload', fileUrl);
          const name = decodeURIComponent(fileUrl).split('/').pop().split('?')[0];
          // make the mutations
          const updateObject = {
            group_id: this.groupId,
            name,
            url: fileUrl,
          };
          console.log('updateObject in DB', updateObject);
          // get the filename
          await this.$apollo.mutate({
            mutation: InsertImage,
            variables: {
              object: updateObject,
            },
          });
          return fileUrl;
        }).then((fileUrl) => {
          const mediaUrlObject = {
            src: fileUrl,
            filetype,
            focal: false,
          };
          this.$emit('upload-image-object', mediaUrlObject);
          progress(true, 100, 100);
          console.log('this.internalFilePath', this.internalFilePath);
          load(this.internalFilePath);
          this.$emit('disable-modal-ok-button', false);
        }).catch((e) => {
          this.$emit('disable-modal-ok-button', false);
          console.error(e);
        });
      } else if (
        files.type
        && ('application/pdf'.includes(files.type))
      ) {
        this.$emit('disable-modal-ok-button', true);
        this.type = 'pdf';
        this.uploadItem(files, timestamp, files.name, this.type).then(async (fileUrl) => {
          console.log('after upload', fileUrl);
          const name = decodeURIComponent(fileUrl).split('/').pop().split('?')[0];
          // make the mutations
          const updateObject = {
            group_id: this.groupId,
            name,
            url: fileUrl,
          };
          console.log('updateObject in DB', updateObject);
          // get the filename
          await this.$apollo.mutate({
            mutation: InsertImage,
            variables: {
              object: updateObject,
            },
          });
          return fileUrl;
        }).then((fileUrl) => {
          const cloudImageSrc = `https://axyqwmwryo.cloudimg.io/v7/${fileUrl}?func=proxy`;
          const mediaUrlObject = {
            src: cloudImageSrc,
            filetype: 'application/pdf',
            focal: false,
          };
          this.$emit('upload-image-object', mediaUrlObject);
          progress(true, 100, 100);
          load(this.internalFilePath);
          this.$emit('disable-modal-ok-button', false);
        }).catch((e) => {
          this.$emit('disable-modal-ok-button', false);
          console.error(e);
        });
      } else if (
        this.customFontFileType && 'font/woff,font/woff2,font/otf,font/ttf'.includes(this.customFontFileType)
      ) {
        this.$emit('disable-modal-ok-button', true);
        this.uploadItem(files, timestamp, files.name, 'font').then(async (fileUrl) => {
          console.log('after upload', fileUrl);
          const name = decodeURIComponent(fileUrl).split('/').pop().split('?')[0];
          // make the mutations
          const updateObject = {
            group_id: this.groupId,
            name,
            url: fileUrl,
          };
          console.log('updateObject in DB', updateObject);
          // get the filename
          await this.$apollo.mutate({
            mutation: InsertImage,
            variables: {
              object: updateObject,
            },
          });
          return fileUrl;
        }).then((fileUrl) => {
          const cloudImageSrc = `https://axyqwmwryo.cloudimg.io/v7/${fileUrl}?func=proxy`;
          const mediaUrlObject = {
            src: cloudImageSrc,
            filetype: this.customFontFileType ? this.customFontFileType : null,
            focal: false,
            url: fileUrl,
          };
          this.$emit('upload-image-object', mediaUrlObject);
          progress(true, 100, 100);
          load(this.internalFilePath);
          this.$emit('disable-modal-ok-button', false);
        }).catch((e) => {
          this.$emit('disable-modal-ok-button', false);
          console.error(e);
        });
      } else {
        this.$emit('disable-modal-ok-button', true);
        Promise.all(
          files.map((item) => this.loadImage(item)),
        ).then((newFiles) => {
          Promise.all(
            newFiles.map((item) => this.uploadItem(item, timestamp, item.file.name)),
          ).then(async (uploadUrls) => {
            console.log('uploadURLS', uploadUrls);
            const promises = uploadUrls.map(async (uploadUrl) => {
              const name = decodeURIComponent(uploadUrl).split('/').pop().split('?')[0];
              // make the mutations
              const updateObject = {
                group_id: this.groupId,
                name,
                url: uploadUrl,
              };
              console.log('updateObject in DB', updateObject);
              // get the filename
              await this.$apollo.mutate({
                mutation: InsertImage,
                variables: {
                  object: updateObject,
                },
              });
              // update resized file
              this.storageObjectOfResizedFile.url = uploadUrl;
            });
            await Promise.all(promises);
          }).then(() => {
            this.$emit('upload-image-object', this.storageObjectOfResizedFile);
            progress(true, 100, 100);
            console.log('this.internalFilePath', this.internalFilePath);
            load(this.internalFilePath);
          }).catch((e) => {
            console.error(e);
          });
        });
        this.$emit('disable-modal-ok-button', false);
      }
    },
    revert(source, load) {
      const sources = [
        this.resizedFilePath,
      ];
      Promise.all(
        sources.map((item) => this.deleteItem(item)),
      ).catch((e) => {
        console.error('Deletion failed: ', e.message);
        console.error(e);
      });
      load();
    },
    load(source, load, error, progress, abort) {
      fetch(source)
        .then((res) => res.blob())
        .then(load)
        .catch((err) => {
          error(err.message);
          abort();
        });
    },
    handleError(error, e) {
      switch (e.code) {
        case 'storage/canceled':
          break;
        default:
          error(e.message);
      }
    },
    handleErrorMethod(err) {
      console.error('onerror', err);
    },
    loadImage(fileObject) {
      return new Promise((resolve) => {
        const file = fileObject;
        file.img = new Image();
        if (file.type === 'image/gif') {
          file.img.src = URL.createObjectURL(file);
        } else {
          file.img.src = URL.createObjectURL(file.file);
        }
        let intervalId = null;
        file.img.onerror = () => {
          file.status = 'bad';
          resolve(file);
        };

        intervalId = setInterval(() => {
          if (file.img.naturalWidth && file.img.naturalHeight) {
            clearInterval(intervalId);
            URL.revokeObjectURL(file.img.src);
            file.width = file.img.naturalWidth;
            file.height = file.img.naturalHeight;
            resolve(file);
          } else {
            file.img.onload = () => {
              file.width = file.img.width;
              file.height = file.img.height;
              resolve(file);
            };
          }
        }, 1);
      });
    },
    deleteItem(source) {
      if (source === null) return true;
      return null;
    },
    uploadItem(item, timestamp, name, type = '') {
      // prepare the filename
      // the return value will be a Promise
      const filenameExtension = name.replace(/^.*[\\/]/, '');
      let filename = filenameExtension.substring(0, filenameExtension.lastIndexOf('.'));
      const ext = filenameExtension.split('.').pop();
      // check if the file already has a timestamp
      // if positionOfLastDash === -1 means there is no timestamp from the system
      const positionOfLastDash = filename.lastIndexOf('-');
      if (positionOfLastDash !== -1) {
        const timestampOfFilename = filename.substring(positionOfLastDash + 1, filename.length);
        // check if the timestamp is valid and remove the timestamp
        if (new Date(Number(timestampOfFilename)).getTime() > 0) {
          filename = `${filename.substring(0, positionOfLastDash)}`;
        }
      }
      const slugifyedName = slugify(`${filename}-${timestamp}`);
      // eslint-disable-next-line no-param-reassign
      item.filename = `${slugifyedName}.${ext}`;

      const month = (`0${new Date().getMonth() + 1}`).slice(-2);
      const folder = `${this.groupSlug}/${new Date().getFullYear()}/${month}`;
      console.log('type to aws', type);
      if (type === 'audio' || type === 'video' || type === 'video_webm' || type === 'pdf') {
        const mediaItem = {
          filename: item.filename,
          file: item,
        };
        console.log('upload mediaItem to aws', folder, mediaItem);
        return uploadFileToAws(folder, mediaItem)
          .then((imageUrl) => imageUrl)
          .catch((err) => {
            console.log(err);
          });
      }
      if (type === 'font') {
        const mediaItem = {
          filename: item.filename,
          file: item,
          type: this.customFontFileType ? this.customFontFileType : null,
        };
        console.log('upload custom font to aws', folder, mediaItem);
        return uploadFileToAws(folder, mediaItem)
          .then((imageUrl) => imageUrl)
          .catch((err) => {
            console.log(err);
          });
      }
      return uploadFileToAws(folder, item)
        .then((imageUrl) => {
          console.log('imageUrl', imageUrl);
          if (item.name && (item.name === '1920x1920' || item.name === 'svg' || item.name === 'gif')) {
            this.storageObjectOfResizedFile.url = imageUrl;
            this.storageObjectOfResizedFile.width = item.width;
            this.storageObjectOfResizedFile.height = item.height;
            this.resizedFilePath = imageUrl;
          }
          return imageUrl;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetFileInput() {
      console.log('FILE UPLOADED');
      if (this.customFontFileType === 'font/woff' || this.customFontFileType === 'font/woff2') {
        this.$refs.pond.removeFile();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../node_modules/pintura/pintura.css';

.image-preview {
  img {
    width: 100%;
  }
}

.filepond--root .filepond--drop-label {
  cursor: pointer;
}

.filepond--root .filepond--drop-label label {
  cursor: pointer;
}

</style>
